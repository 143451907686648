import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import useToken from './helpers/useToken';
import useRole from './helpers/useRole';


function App() {

  const { token, setToken } = useToken();
  const { role, setRole } = useRole();

  //console.log('App.js Token', token);
  const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

  // Containers
  const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

  // Pages
  const Login = React.lazy(() => import('./views/Pages/Login'));
  const Register = React.lazy(() => import('./views/Pages/Register'));
  const Page404 = React.lazy(() => import('./views/Pages/Page404'));
  const Page500 = React.lazy(() => import('./views/Pages/Page500'));
  const ForgetPass = React.lazy(() => import('./views/Pages/ForgetPass'));

  //console.log('ENTRE A APP.JS');
  console.log('Este el el token que tengo', token);
  //console.log('Este es el Role que tengo', role);

  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
          <Route exact path="/forgetpass" name="Olvide mi contraseña" render={props => <ForgetPass />} />
          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          {/*  <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} /> */}

          <Route
            path="/"
            name="Home"
            render={props => {
              return (
                !token ?
                  <Login setToken={setToken} setRole={setRole} {...props} /> :
                  <DefaultLayout {...props} />
              )
            }}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}


export default App;
